<template>
  <div class="main-container"> 
    <img :src="require('../assets/bg1.jpg')" class="bg">

    <div class="wrapper">
      <div id="form">
        <div class="logo">
          <img :src="require('../assets/logo.png')" >
        </div>
        
         <div class="d-flex flex-wrap  tabs">
            <div class="d-flex justify-content-center tab active" :class="{'isOnStep': active == 1}">
              <div class="number my-auto active">
                <h6>1</h6>
              </div>
              <h4>Liveness Check</h4>
            </div>
            <div class="d-flex justify-content-center tab" :class="{'isOnStep': active == 2, active: active >= 2}">
              <div class="number my-auto" :class="{active: active >= 2}">
                <h6>2</h6>
              </div>
              <h4>Upload ID</h4>
            </div>
            <!-- <div class="d-flex justify-content-center tab" :class="{active: active == 3}">
              <div class="number my-auto" :class="{active: active == 3}">
                <h6>3</h6>
              </div>
              <h4>Account Verification</h4>
            </div> -->
         </div>

         <div class="form" v-if="active == 1">
            

         
         </div>

 
         <div class="form" v-if="active == 2">

            <h4 class="text-center">Upload your ID</h4>

            <form class="mt-5">
              <!-- <div class="form-group" style="width:28%;">
                <label for="exampleFormControlInput1">Passport Number</label>
                <button class="btn btn-main" @click="uploadPoid('PASSPORTNUMBER', 'Passport Number')" :disabled="isDone.PASSPORTNUMBER">UPLOAD</button>
              </div> -->

              <div class="d-flex flex-wrap justify-content-between">
                <div class="form-group">
                  <label for="exampleFormControlInput1">Select ID Type</label>
                   
                  <div class="input-group mb-3">
                    
                    <select class="form-control" v-model="idType">
                      <option value="PASSPORTNUMBER" > PASSPORT </option>
                    <!--   <option value="NATIONALID" > NATIONAL ID </option> -->
                      <!-- <option value="DRIVERSLICENSE" > DRIVER'S LICENSE </option> -->
                    </select>

                    <div class="input-group-append">
                      <button class="btn btn-main" type="button" @click="uploadPoidV2">
                         
                        <span>UPLOAD</span>
                      </button>
                    </div>

                  </div>


                </div>


              
              </div>
            </form>

            <!-- <h4 class="text-center">Complete Your Contact Details</h4>

            <form class="mt-5">
              <div class="alert alert-danger" v-if="errors.profile.msg">
                {{ errors.profile.msg }}
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="form-group" style="width:47.5%;position:relative;" v-if="init.profile.emailAddress">
                  <label for="exampleFormControlInput1">Mobile Number</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn" type="button" v-on:click="toggleCountryList">
                        <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                        <span>+{{ mobileCode }}</span>
                      </button>
                    </div>
                    <input type="tel" class="form-control" @keypress="isNumber($event)" v-model="contact.mobilePhone">
                  </div>
                  <div class="country-list" v-if="showCountry">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                        <div>
                          <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name"> <span> {{ l.country }} </span> </div>     
                      </div>  
                    </div>
                  </div>
                </div>
                <div class="form-group" style="width:47.5%;"  v-if="init.profile.mobilePhone">
                  <label for="exampleFormControlInput1">Email Address</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="contact.emailAddress">
                </div>
                <div class="form-group" style="width:47.5%;position:relative;">
                  <label for="exampleFormControlInput1">Country</label>
                  <select class="form-control" id="exampleFormControlSelect1" v-model="contact.presentAddress.country" required>
                    <option :value="l.countryCode" v-for="l in list.mobileCodes" :key="l.id">{{ l.country }}</option>
                  </select>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="form-group" style="width:47.5%;">
                  <label for="exampleFormControlInput1">Province / State</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="contact.presentAddress.provState">
                </div>
                <div class="form-group" style="width:47.5%;">
                  <label for="exampleFormControlInput1">City / Town</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="contact.presentAddress.cityTown">
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="form-group" style="width:47.5%;">
                  <label for="exampleFormControlInput1">Number / Street</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="contact.presentAddress.numberStreet">
                </div>
                <div class="form-group" style="width:47.5%;">
                  <label for="exampleFormControlInput1">Postal / Zip Code</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="contact.presentAddress.postalCode">
                </div>
              </div>
            </form>

            <div class="d-flex justify-content-between">
              <div></div>
              <div class="d-flex flex-wrap align-self-right" style="align-self:flex-end;">
                <button class="btn d-flex justify-content-around btn-white" @click="setContact" v-if="!isSaving.contact">
                    <h6 class="my-auto" style="margin-top:5px;">NEXT</h6>
                    <v-icon name="chevron-right"/> 
                  </button>
                  <button class="btn d-flex justify-content-around btn-white" disabled v-else>
                    <v-icon name="spinner" pulse scale="1"/>
                  </button>
              </div>
            </div> -->
         </div>
         <div class="form" v-if="active == 3">
            <h4 class="text-center">Verify Your Account</h4>

            <form class="mt-5">
              <div class="alert alert-danger" v-if="errors.profile.msg">
                {{ errors.profile.msg }}
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <!-- <div class="form-group" style="width:28%;">
                  <label for="exampleFormControlInput1">Driver’s License Number </label>
                  <button class="btn btn-main" @click="uploadPoid('DRIVERSLICENSE', 'Driver’s License Number')" :disabled="isDone.DRIVERSLICENSE">UPLOAD</button>
                </div>
                <div class="form-group" style="width:28%;">
                  <label for="exampleFormControlInput1">Passport Number</label>
                  <button class="btn btn-main" @click="uploadPoid('PASSPORTNUMBER', 'Passport Number')" :disabled="isDone.PASSPORTNUMBER">UPLOAD</button>
                </div> -->
                <div class="form-group" style="width:45%;">
                  <label for="exampleFormControlInput1">Proof of Address</label>
                  <button class="btn btn-main" @click="uploadPoid('POA', 'Proof of Address')" :disabled="isDone.POA">UPLOAD</button>
                </div>

                <div class="form-group" style="width:45%;">
                  <label for="exampleFormControlInput1">Home Asset</label>
                  <button class="btn btn-main" @click="uploadPoid('HOMEASSET', 'Home Asset')" :disabled="isDone.HOMEASSET">UPLOAD</button>
                </div>

              </div>
              <div class="d-flex flex-wrap justify-content-between">
                
                <div class="form-group" style="width:45%;">
                  <label for="exampleFormControlInput1">Vehicle Asset</label>
                  <button class="btn btn-main" @click="uploadPoid('VEHICLEASSET', 'Vehicle Asset')" :disabled="isDone.VEHICLEASSET">UPLOAD</button>
                </div>
                <div class="form-group" style="width:45%;">
                  <label for="exampleFormControlInput1">Other ( NBI, Police Clearance, Voter’s ID)</label>
                  <button class="btn btn-main" @click="uploadPoid('OTHER', 'Other IDs')">UPLOAD</button>
                </div>
              </div>
            </form>

            <div class="d-flex justify-content-between mt-3">
              <div></div>
              <div class="d-flex flex-wrap align-self-right" style="align-self:flex-end;">
                <button class="btn d-flex justify-content-around btn-white" @click="done" v-if="!isSaving.account">
                  <h6 class="my-auto" style="margin-top:5px;">SAVE</h6>
                  <v-icon name="chevron-right"/> 
                </button>
              </div>
            </div>
         </div>
      </div>
    </div>  
    <UploadPoid :type="type" :label="label" :isSetup="true"/> 
  </div>
</template>

<script>

  import qs from 'querystring'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import UploadPoid from '@/components/modal/UploadPoid.vue'

  export default {
    name: 'SetupProfile',
    components: {
      DatePicker,
      UploadPoid
    },
    data: function() {
      return  {
        idType: "",
        active: 1,
        country: 'PH',
        mobileCode: '63',
        showCountry: false,
        list: {
          mobileCodes: []
        },
        isSaving: {
          profile: false,
          contact: false,
          account: false
        },
        isDone: {
          DRIVERSLICENSE: false,
          PASSPORTNUMBER: false,
          POA: false,
          HOMEASSET: false,
          VEHICLEASSET: false,
          OTHER: false
        },
        profile: {
          title: "",
          firstName: "",
          lastName: "",
          birthDate: "",
          gender: "",
          citizenship: "",
          occupation: "",
          natureOfWork: "",
          employer: "",
          selfieFile: null
        },
        contact: {
          mobilePhone: "",
          emailAddress: "",
          presentAddress: {
            addressExtraInfo: "",
            cityTown: "",
            country: "",
            numberStreet: "",
            postalCode: "",
            provState: ""
          }
        },
        type: "",
        label: "",
        account: {
          driversLicense: null,
          passport: null,
          poa: null,
          homeAsset: null,
          vehicleAsset: null,
          other: null
        },
        config: {
          file: null,
        },
        errors: {
          profile: {
            show:false,
            msg: ""
          }
        }
      }
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
      show() {
        return this.$store.state.show;
      },
    },
    methods: {
      changeStep(state) {
        if(state == 'back') {
          this.active--
        } else {
          this.active++
        }
      },

      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);
          })
      },

      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },

      uploadPoid(type, label) {
        console.log('.')
        this.type = type
        this.label = label
        this.$bvModal.show('upload-poid-modal'); 
      },

      uploadPoidV2(type, label) {
        
        switch(this.idType) {
          case "PASSPORTNUMBER":
            this.type = "PASSPORTNUMBER"
            this.label = "Passport"
            break;

          case "DRIVERSLICENSE":
            this.type = "DRIVERSLICENSE"
            this.label = "Driver's License"
            break;
        }

        
        this.$bvModal.show('upload-poid-modal'); 
      },

      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },

      setProfile: function() {
        this.isSaving.profile = true

        let birthYear = this.profile.birthDate.substring(0, this.profile.birthDate.indexOf('-'))
        let birthMm = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 1, this.profile.birthDate.indexOf('-') + 3)
        let birthDd = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 4, this.profile.birthDate.length)

        let formData = new FormData();
        formData.append('firstName', this.profile.firstName); 
        formData.append('lastName', this.profile.lastName);
        formData.append('birthYear', birthYear);
        formData.append('birthMm', birthMm);
        formData.append('birthDd', birthDd);
        formData.append('title', this.profile.title);
        formData.append('gender', this.profile.gender);
        formData.append('citizenship', this.profile.citizenship);
        formData.append('occupation', this.profile.occupation);
        formData.append('natureOfWork', this.profile.natureOfWork);
        formData.append('employer', this.profile.employer);
        formData.append('selfieFile', this.profile.selfieFile);

        this.axios.post('/cx/chain/submitprofileinfo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            this.uploadAvatar();
          }).catch((err) => {
            this.isSaving.profile = false
            if(err.response.data.fieldErrors) {
              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
            } else {
              this.errors.profile.msg = err.response.data.msgText  
            }   
            setTimeout(() => {
              this.errors.profile.msg = ''
            }, 5000)  
          })
      },

      livenessCheck() {
        const options = {
          method: 'POST', 
          url: '/cx/profile/liveness',
        };

        this.axios(options)
          .then((response) => {
               this.init.entityId = response.data.entityId;
     

              if(response.data.status == 0 ) {
                window.location.href = response.data.redirectUrl;
              } else {
                this.active = 2;
                this.getPoids();
              }
            }).catch((err) => {
                
              console.log(err)     

            
            })
      },

      uploadAvatar: function() {

        let formData = new FormData();
        formData.append('avatarImageFile', this.profile.selfieFile);

        this.axios.post('/cx/uploadavatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            this.isSaving.profile = false
            
            
          }).catch((err) => {
            this.isSaving.profile = false
            if(err.response.data.fieldErrors) {
              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
            } else {
              this.errors.profile.msg = err.response.data.msgText  
            }   
            setTimeout(() => {
              this.errors.profile.msg = ''
            }, 5000)  
          })
      },

      setContact: function() {
        this.isSaving.contact = true

        let mobilePhone = this.mobileCode + this.contact.mobilePhone

        let formData = new FormData();
        formData.append('mobilePhone', this.init.profile.mobilePhone == null ? mobilePhone : this.init.profile.mobilePhone); 
        formData.append('emailAddress', this.init.profile.emailAddress == null ? this.contact.emailAddress : this.init.profile.emailAddress);
        formData.append('presentAddress.addressExtraInfo', this.contact.presentAddress.addressExtraInfo);
        formData.append('presentAddress.cityTown', this.contact.presentAddress.cityTown);
        formData.append('presentAddress.country', this.contact.presentAddress.country);
        formData.append('presentAddress.numberStreet', this.contact.presentAddress.numberStreet);
        formData.append('presentAddress.postalCode', this.contact.presentAddress.postalCode);
        formData.append('presentAddress.provState', this.contact.presentAddress.provState);

        this.axios.post('/cx/chain/submitcontactdetails', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            this.isSaving.contact = false
            this.getProfile();
          }).catch((err) => {
            this.isSaving.contact = false
            if(err.response.data.fieldErrors) {
              this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
            } else {
              this.errors.profile.msg = err.response.data.msgText  
            }       
          })
      },

      done() {
        this.$router.push('/')
      },

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

      getProfile: function(){
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/profile/',
        };

        this.axios(options)
          .then((response) => {

            // this.init.profile = response.data;   
            // if(response.data.occupation) {
            //   this.active = 2
            // } if(response.data.presentAddress.numberStreet) {
            //   this.active = 3
            // }

            

            // this.profile.firstName = response.data.firstName
            // this.profile.lastName = response.data.lastName
            // this.profile.birthYear = response.data.birthYear
            // this.profile.birthMm = response.data.birthMm
            // this.profile.birthDd = response.data.birthDd
            // this.profile.title = response.data.title
            // this.profile.gender = response.data.gender
            // this.profile.citizenship = response.data.citizenship
            // this.profile.occupation = response.data.occupation
            // this.profile.natureOfWork = response.data.natureOfWork
            // this.profile.employer = response.data.employer

            // this.contact.mobilePhone = response.data.mobilePhone
            // this.contact.emailAddress = response.data.emailAddress
            // this.contact.presentAddress.cityTown = response.data.presentAddress.cityTown
            // this.contact.presentAddress.country = response.data.presentAddress.country
            // this.contact.presentAddress.numberStreet = response.data.presentAddress.numberStreet
            // this.contact.presentAddress.postalCode = response.data.presentAddress.postalCode
            // this.contact.presentAddress.provState = response.data.presentAddress.provState
          })
      },

      getPoids() {
        const options = {
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/cx/poids',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((value) => {

                  if(value.poidType == "PASSPORTNUMBER" || 
                    value.poidType == "PASSPORT" 
                    ) {

                    window.location.href = "/"

                  }
                  //console.log(value)
                  this.isDone[value.poidType] = true
                })

            })
            .catch((error) => {
                
            });
      },

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => {

              console.log(response)
            }).catch((err) => {
                
              console.log(err.response.data)     

              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  
            })
      },

    },

    beforeMount(){

      this.checkSession();
      //this.getProfile();
      
      // this.getMobileCodes();
      this.livenessCheck();


    }
  }
</script>

<style scoped lang="scss">
  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:3em;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    padding:2em;
    img{
      width: 150px;  
    }
    
  }

  .wrapper { 
    background: white;
    color: #000;
    width:80%;
    border-radius:10px;
  }

  .tabs {
    width:100%;
    .tab {
      padding:1em;
      color:black;
      width:33.3333333%;
      text-align:center;
      &.active {
        background: #062035;
        color:white;
      }
      &.isOnStep {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
      .number {
        width:1.5em;
        height:1.5em;
        margin-right:0.2em;
        background: black;
        color:#fff;
        position:relative;
        border-radius:15px;
        &.active {
          background: white;
          color:#0e649e;
        }
        h6 {
          position: absolute;
          top: 20%;
          bottom: 20%;
          left: 15%;
          right: 15%;
        }
      }
      h4 {
        margin-left:0.2em;
        margin-top:5px;
        margin-bottom:0;
      }
    }
  }

  .form {
    padding:2em;
    .form-group {
      width:40%;
    }
    .mx-datepicker {
      width:100%;
    }
    .btn-white {
      width:6em;
    }

    .default-flag {
      width: 33px;
      margin-right:0.25em;
    }

    .country-list {
      position: absolute;
      background: #ccc;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
      .row {
        margin: 0 !important;
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
        }
      }
    }

    .flag-list-img {
      width: 33px;
    }
  }

  .btn-save {
    background-color: #0e649e !important;
    color: #FFF !important;
  }


  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

     #app {
      
      padding: 2em;
      font-size: 85%;

    }
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
 
  }
</style>



