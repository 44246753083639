<template>
	<b-modal id="upload-poid-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				UPLOAD <span style="text-transform:uppercase;">{{ label }}</span>
			</h5> 
			<div></div>

    </template>


		<div> 
			<form v-if="state == 'init'">
				<div class="alert alert-danger" v-if="errors.profile.msg">
					{{ errors.profile.msg }}
				</div>
				<div class="form-group" v-if="type == 'OTHER'">
					<label for="">Description:</label>
					<input type="text" class="form-control" v-model="description">
				</div>     
				        

				<div class="form-group" v-if="!isSetup">
					<label for="">Type:</label>
					<select v-model="idType" class="form-control" >
						<option disabled value="">Please select one</option>
						<option value="DRIVERSLICENSE">Driver's License</option>
						<option value="PASSPORTNUMBER">Passport</option>
						<option value="POA">Proof of Address</option>
						<option value="NATIONALID">National ID</option>
						<option value="BIRTHCERTIFICATE">Birth Certificate</option>
						<option value="HOMEASSET">Home Asset</option>
						<option value="VEHICLEASSET">Vehicle Asset</option>
						<option value="EDUCATION">Educational Certificate</option>
						<option value="EMPLOYMENTCERT">Employment Certificate</option>
						<option value="NBICLEARANCE">NBI Clearance</option>
						<option value="VOTERSID">Voter's ID</option> 
					</select>
				</div> 
				<div class="form-group" v-if="!isSetup">
					<label for="">ID Number:</label>
					<input type="text" class="form-control" v-model="idNumber">
				</div>    

				<label for="file">File:</label>
				<b-form-file
				v-model="file"
				:state="Boolean(file)"
				:placeholder="$t('createAsset.filePlaceholder')"
				:drop-placeholder="$t('createAsset.dropPlaceholder')"
				></b-form-file>
				<div class="flex  margin-top">
					<button  class="btn btn-main" type="button" @click="setAccount(type, label)" :disabled="isSaving">  
						<span v-if="isSaving && isSetup"> EXTRACTING YOUR UPLOADED FILE. PLEASE WAIT </span> 
						<span v-if="isSaving && !isSetup"> UPLOADING YOUR FILE. PLEASE WAIT </span> 
						<span v-if="!isSaving">{{ $t('createAsset.upload') }}</span> 
					</button>					
				</div>
			</form>

			<div v-if="state == 'details'">
				<div>
					<p> <span class="bold"> First Name: </span>  {{ profile.firstName }} </p>
					<p> <span class="bold"> Last Name: </span>   {{ profile.lastName }} </p>
					<p><span class="bold"> Birthdate: </span>   {{ profile.birthMm }}-{{profile.birthDd }}-{{profile.birthYear}} </p>
					<p> <span class="bold"> ID Number: </span>   {{ profile.idNumber }} </p>
				</div>

				<div class="flex  margin-top">
					<button  class="btn btn-main" type="button" @click="refresh" >  
						DONE
					</button>					
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
 import Pusher from 'pusher-js' 

export default {
  name: 'ExportImportFund',
  props: ["type", "label", "isPreset", "isSetup"],
  data: function() {
      return  {
      	state: 'init',
		isSaving: false,
		idNumber: "",
		idType: "",
		description: "",
		file: null,
		profile: {},
		errors: {
			profile: {
				msg: ''
			}
		}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
   	},
   	methods: {
 
   		done() {
   			window.location.reload();
   		},
   		// getProfile: function(){
      //       const options = {
      //           method: 'GET',
      //           headers: { 'content-type': 'application/x-www-form-urlencoded' },
      //           url: '/cx/profile/',
      //       };

      //       this.axios(options)
      //           .then((response) => {
  		// 							console.log(response.data)
 
  		// 							this.state = 'details';
  		// 							this.profile = response.data;
      //           })
      //   },


       uploadForExtraction() {
	       	let formData = new FormData();
	       	formData.append('imgFile', this.file); 

	       	this.axios.post('/cx/ai/uploadpoid', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
					})
					.then((response) => {
						console.log(response);
					}).catch((err) => {
						console.log(err)
					})
       },

		setAccount: function(type, label) {
			let hasError = false
			// if(this.idNumber == '') {
			// 	hasError = true
			// 	this.errors.profile.msg = 'ID Number is required'
			// }

			if(this.file == null) {
				hasError = true
				this.errors.profile.msg = 'File is required'
			}

			if(!hasError) {
				let formData = new FormData();
				formData.append('poidType', this.idType); 
				formData.append('poidDescription', this.description != '' ? this.description : label); 
				formData.append('poidNumber', this.idNumber); 
				formData.append('poidImageFile', this.file); 
				
				this.isSaving = true;
				this.axios.post('/cx/uploadpoid', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
				})
				.then((response) => {
					//window.location.reload();

					if(type == 'PASSPORTNUMBER' || type == 'PASSPORT') {
						this.uploadForExtraction();
					} else {
						window.location.reload();
					}

					
				}).catch((err) => {
					this.isSaving = false;
					if(err.response.data.fieldErrors) {
					this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
					} else {
					this.errors.profile.msg = err.response.data.msgText  
					}     
				})
			}

		},

		refresh() {
        const options = {
          method: 'POST', 
          url: '/cx/profile/refresh',
        };

        this.axios(options)
          .then((response) => {
              this.done()
            }).catch((err) => {
                
              console.log(err)     

            
            })
      },

   		close: function(){
   			this.$bvModal.hide('upload-poid-modal'); 
   		},	
   	},
   	mounted(){
   		 this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    		console.log('extract_result_' + this.init.entityId);
	    	const pusher = new Pusher('62fcbaeaf45d18d8eadf', {
          cluster: 'ap1'
        });

        var channel = pusher.subscribe('idchain');
        channel.bind('extract_result_' + this.init.entityId , (data) => {
         console.log(data)

         if(data.message == 'EXTRACT_FAILED') {
         	this.errors.profile.msg = "Error Extracting data from the uploaded file. Please upload better image."
         	this.isSaving = false;
         } else if(data.message == 'ID_EXPIRED') {
         	this.errors.profile.msg = "Expired ID or expiring in 6 months.. Please upload another ID."
         	this.isSaving = false;
         } else if(data.message == 'NAME_ALREADY_EXIST') {
         	this.errors.profile.msg = "Account with your name is already exist."
         	this.isSaving = false;
         } else {
         	this.state = 'details';
  				this.profile = JSON.parse(data.message);


         }
        });


        if(this.isSetup) {
        	
        	setTimeout(() => {
        		this.idType = this.type;
        		console.log(this.idType)
        	}, 500 )
        	
        } else {
        	//this.id
        }

	    })

	    
 
 
 	},

 	watch: {
      'idType': function(newValue, oldValue) {

        if (newValue != oldValue) {
        	this.description = newValue;
        }
      },

    }
}
</script>
 
<style scoped lang="scss">
	 
	 .bold {
	 	font-weight: bold;
	 }
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }
	
</style>
